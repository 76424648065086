import * as React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import WorksDetail from '../../components/works/WorksDetail'
import { Works } from '../../interface'
import SEO from '../../components/seo'

type Props = {
  items: Works[]
  pathname: string,
  data: any
}

class WorksDetailPage extends React.Component<Props> {
  render() {
    const { data } = this.props;
    const work = data.wordpressWpWorks;

    // works_hide のチェック（配列対応）
    const shouldNoIndex = Array.isArray(work?.acf?.works_hide) 
      ? work.acf.works_hide.includes("1") 
      : work?.acf?.works_hide == "1";

    return (
      <Layout>
        <SEO 
          title={work.title} 
          meta={shouldNoIndex ? [{ name: "robots", content: "noindex, nofollow" }] : []} 
        />
        {work && <WorksDetail data={work} />}
      </Layout>
    );
  }
}

export default WorksDetailPage

export const query = graphql`
  query($slug: String!) { 
    wordpressWpWorks(slug: { eq: $slug }) {
      content
      featured_image_src
      title
      acf {
        subtitle
        works_images {
          works_images_image {
            url
          }
          works_images_image_caption
          works_images_image_number_of_columns
        }
        works_hide
      }
    }        
  }
`;
